@use "common/lib/mixins" as *;
@use "theme/variables" as *;

.flex-row {
    align-items: center;
    display: flex;
    gap: $spacer-1;
}

.app {

    .btn:not(.btn-link) {

        .btn-success {
            background: $success-3;

            &:hover {
                background: $success-2;
            }
        }

        .btn-danger {
            background: $danger-3;

            &:hover {
                background: $danger-2;
            }
        }
    }
}

.btn-toolbar {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    margin-bottom: $spacer-2;
    padding: 0;

    .c-button-group {
        box-shadow: $shadow-down-d;
        margin-right: $spacer-2;
    }

    .btn {
        margin: 0;
        margin-bottom: $spacer-1;
        margin-right: $spacer-1;
    }

    .stats-group {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        width: 100%;

        .bs-callout {
            align-items: center;
            border-radius: 0;
            color: $grey-2;
            cursor: pointer;
            display: flex;
            font-weight: 600;
            gap: $spacer-05;
            height: $input-height;
            margin: 0;
            padding: 0 $spacer-1;
        }
    }
}

.box-small {
    max-width: calc(600px - #{$spacer-2});
}

.c-checkbox {
    align-items: center;
    display: flex;

    input[type="checkbox"] {

        & + label {
            font-weight: 600;
            margin-left: $spacer-1;

            i {
                margin-left: $spacer-1;
            }
        }
    }
}

.btn-group {
    display: inline-flex;

    .btn {
        flex: 1;
    }
}

.btn:not(.btn-link) {
    align-items: center;
    border: 1px solid $input-border-color;
    border-radius: 0;
    box-shadow: none !important;
    display: inline-flex;
    font-size: $font-d;
    gap: $spacer-1;
    height: $input-height;
    justify-content: center;

    &.flex {
        align-items: center;
        display: flex;
        gap: $spacer-1;
        justify-content: center;
        width: 100%;
    }

    &.btn-default {
        background: $surface-5;
        border-color: $surface-4;
        color: $surface-2;

        &.active,
        &:hover {
            background: $surface-4;
            border-color: $surface-3;
            color: $surface-1;
        }
    }

    &.btn-info {
        background: $info-2;
        border-color: $info-4;
        color: $info-5;

        &.active,
        &:hover {
            background: $info-1;
            border-color: $info-3;
            color: $info-5;
        }

        &.reversed {
            background: $info-2;
            border-color: $info-3;
            color: $info-5;

            &.active,
            &:hover {
                background: $info-1;
                border-color: $info-2;
                color: $info-4;
            }
        }
    }

    &.btn-primary {
        align-items: center;
        background: $info-2;
        border-color: $info-5;

        input[type="radio"] {
            flex: 1;
            height: 100%;
            width: 100%;
        }

        &.active,
        &:hover {
            background: $info-1;
        }
    }

    &.btn-success {
        background: $success-2;
        border: none;
        border-color: $success-5;
        box-shadow: none;
        color: $success-5;

        &.active,
        &:hover {
            background: $success-1;
            color: $success-4;
        }
    }

    &:focus {
        box-shadow: none;
        outline: $focus-outline;
    }
}

.btn-link {
    color: $brand-3;
    margin: 0;
    padding: 0;

    &:hover {
        color: $info-3;
    }
}

.discover-heading {
    font-weight: 600;
}

.panel {
    border: none;
    border-radius: 0;

    &.panel-default {
        border: none;

        .panel-heading {
            background: $surface-5;
            color: $surface-2;
        }

        .spli-suggestion {
            margin-bottom: $spacer-1;
            padding: 0;
        }
    }

    &.panel-primary {

        .panel-heading {
            align-items: center;
            background: $info-1;
            border: none;
            border-radius: 0;
            color: $info-5;
            display: flex;
            font-weight: 600;
            height: $table-header-height;
            margin: 0;
            padding: 0 0 0 $spacer-2;

            > .row {
                align-items: center;
                display: flex;
                height: $table-header-height;
            }
        }
    }
}

.panel-info {

    .panel-heading {
        background: $surface-5;
        border-color: $surface-4;
    }

    .panel-body {
        background: $surface-6;
    }
}

.badge {

    &.badge-default {
        background: hsl(var(--default-h) var(--s-1) var(--l-2));
        color: hsl(var(--default-h) var(--s-1) var(--l-5));
    }

    &.badge-danger {
        background: hsl(var(--danger-h) var(--s-2) var(--l-2));
        color: hsl(var(--danger-h) var(--s-2) var(--l-5));
    }

    &.badge-primary {
        background: hsl(var(--info-h) var(--s-2) var(--l-2));
        color: hsl(var(--info-h) var(--s-2) var(--l-5));
    }


    &.badge-success {
        background: hsl(var(--success-h) var(--s-2) var(--l-2));
        color: hsl(var(--success-h) var(--s-2) var(--l-5));
    }
}

.label-info {
    background: hsl(var(--info-h) var(--s-3) var(--l-2));
}

.navbar-default {
    background: none;
}

html {
    font-size: 1rem;

    .note-link-popover {
        display: none !important;
    }

    body {
        font-size: $font-d;

        a {
            color: hsl(var(--info-h) var(--s-3) var(--l-1));
            cursor: pointer;

            &:hover {
                color: hsl(var(--brand-h) var(--s-3) var(--l-3));
                text-decoration: none;
            }
        }
    }
}

// Override Bootstrap

.form-control {
    box-shadow: none;

    &:focus {
        box-shadow: none;
        outline: $focus-outline;
    }
}

.alert {

    &.alert-info {
        background: hsl(var(--info-h) var(--s-2) var(--l-5));
        border-color: hsl(var(--info-h) var(--s-2) var(--l-4));
        color: $info-1;
    }

    &.alert-danger {
        background: hsl(var(--danger-h) var(--s-2) var(--l-5));
        border-color: hsl(var(--danger-h) var(--s-2) var(--l-4));
        color: $danger-1;
    }

    .header {
        align-items: center;
        display: flex;
        font-weight: 600;
        margin-bottom: $spacer-05;
    }
}

.widget-wrapper {
    cursor: pointer;
    width: 100%;
}

.flex {
    display: flex;
}
.flex-1 { flex: 1; }
.flex-2 { flex: 2; }
.flex-3 { flex: 3; }
.flex-4 { flex: 4; }
.flex-5 { flex: 5; }
.flex-6 { flex: 6; }
.flex-7 { flex: 7; }
.flex-8 { flex: 8; }
.flex-9 { flex: 9; }
.flex-10 { flex: 10; }
.flex-11 { flex: 11; }
.flex-12 { flex: 12; }

.leaflet-control-attribution {
    display: none;
}

.well {

    &.large {
        max-width: 1200px;
    }
}

.account-slug-icon {
    height: $spacer-2;
    margin-right: $spacer-1;
    width: $spacer-2;
}

.c-spinner.table-spinner {
    color: $brand-3;
    height: $spacer-5;
    width: $spacer-5;
}

.link-action {
    color: $info-1;
    cursor: default;

    &:hover {
        color: $brand-3;
    }
}

.table-row tr.danger {
    background: $danger-5;
}

.table-row tr.warning {
    background: $warning-5;
}

.table-row tr.success {
    background: $success-5;
}

// Styling to keep old collections in sync with CollectionItems

.search-table {

    tbody {

        tr {

            td.cell-status-wrapper {
                height: 100%;
                padding: 0;

                .cell-status {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    gap: $spacer-05;
                    height: 100%;
                    justify-content: center;
                    padding: 0;

                    .c-icon {
                        @include square($spacer-1 * 2.5);
                    }
                }
            }

            td.cell-actions-wrapper {

                .cell-actions {
                    gap: $spacer-1;
                    opacity: 0;
                }
            }

            &.selected,
            &:hover {

                td.cell-actions-wrapper {
                    background: linear-gradient(90deg, transparent, hsl(var(--default-h) var(--s-1) var(--l-5)));

                    .cell-actions {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
